<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">掲示板<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{name: 'Dashboard'}"> ダッシュボード </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Admin board archive', params: { type: 'board' }}">掲示板</router-link>
            </li>
            <li><span>詳細</span></li>
          </ul>
        </nav>
      </div>
      <!-- <SuccessMessage v-if="successMessage" /> -->

      <div class="command_wrap">
        <div class="leftarea">
        </div>
        <!--leftarea-->
        <div class="rightarea btn_wrap delete">
          <a class="btn_norm btn_copy delete">
            <button class="js-modal-open">削除</button>
          </a>
        </div>
        <!--rightarea-->
      </div>

      <div class="command_wrap command_bottom">
        <div class="leftarea btn_wrap">
          <div class="return_link">
            <router-link :to="{ name: 'Admin board archive', params: { type: 'board' }}">
              <span class="icon"><i class="fas fa-chevron-left"></i></span>掲示板一覧
            </router-link>
          </div>
          <div></div>
        </div>
        <!--leftarea-->
        <div class="rightarea"></div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->
      <!-- <Spinner v-if="spinner" /> -->

      <div class="contentout_wrap">
        <div class="content_wrap twocolumn">
          <div class="box_wrap left">
            <h2 class="ttl_2">掲示板詳細</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="formtable">
                  <tbody>
                    <tr v-if="messagecolumn['title']">
                      <th class="wid_200px">{{messagecolumn['title']}}</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showmessage.title }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">本文</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showmessage.content"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>

                    <tr v-if="images.length > 0">
                      <th class="wid_200px">投稿画像</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default post_img clearfix">
                                <div class="post_img_wrap" v-for="(image, index) in images" :key="image.id">
                                  <a class="pop-modal" :href="'#inline-wrap'+ index">
                                    <div class="post_img_item" v-bind:style='{ backgroundImage: "url(" + image.imgpath + ")", }'>
                                      <div class="hover_text">
                                        <div class="txt_wrap">
                                          <i class="fas fa-search-plus"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                  <div :id="'inline-wrap'+ index" class="mfp-hide inline-wrap all_img" data-group="1">
                                    <div class="img_list">
                                      <div class="img_wrap"><img :src="image.imgpath"></div>
                                    </div>
                                    <button title="Close (Esc)" type="button" class="mfp-close">×</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>

                    <tr v-if="files.length > 0">
                      <th class="wid_200px">関連資料</th>
                      <td>
                        <div class="row_form_wrap">
                          <div class="row_wrap header">
                            <div class="col">
                              <p class="ttl_3">タイトル</p>
                            </div>
                            <div class="col">
                              <p class="ttl_3">アップロードファイル</p>
                            </div>
                          </div>
                        </div>
                          <div
                            class="row_form_wrap m_b20"
                            v-for="file in files"
                            :key="file.id"
                          >
                            <div class="row_wrap">
                              <div class="col">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <p class="f_500">
                                        {{ file.display_name }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                              <div class="col item_list">
                                <div class="form_outwrap">
                                  <div
                                    class="
                                      wrap
                                      wid_100per
                                      celltxtarea
                                      m_b0
                                      p_r0
                                    "
                                  >
                                    <div class="in_wrap">
                                      <a
                                        style="cursor: pointer"
                                        @click="
                                          dlfile(file.storage_file_name)
                                        "
                                        target="_blank"
                                        class="links"
                                        >{{ file.updaload_name }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                                <!--form_outwrap-->
                              </div>
                            </div>
                            <!--row_wrap-->
                          </div>
                        <!-- <span v-else>関連資料なし</span> -->
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">いいね数</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showmessage.good_num"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">コメント数</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showmessage.reply_num"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--formtable-->
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap -->
          </div>
          <!--box_wrap-->
          <div class="box_wrap right">
            <h2 class="ttl_2">公開情報</h2>
            <div class="form_outwrap">
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開ステータス</p></div>
                <div class="in_wrap">
                  <p>{{ showmessage.status }}</p>
                </div>
              </div>
              <!--wrap-->

              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開日付</p></div>
                <div class="in_wrap">
                  <p>{{ formatDate_time(showmessage.published_at) }}</p>
                </div>
              </div>
              <!--wrap-->
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">記事登録</p></div>
                <div class="in_wrap">
                  <p v-if="showmessage.from_user">
                    登録日： {{ formatDate_time(showmessage.created_at) }}
                    <br />作成者：{{showmessage.from_user.last_name}}{{showmessage.from_user.first_name}}
                  </p>
                </div>
              </div>
              <!--wrap-->
              <!-- <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">最終更新</p></div>
                <div class="in_wrap">
                  <p>
                    更新日：{{ formatDate_time(showmessage.updated_at) }}
                    <br />作成者：{{ userFullName }}
                  </p>
                </div>
              </div> -->
              <!--wrap-->
            </div>
            <!--form_outwrap-->
          </div>
          <!--box_wrap-->
          <div class="box_wrap left">
            <h2 class="ttl_2">返信コメント一覧</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <div class="reply_area" v-if="reply_messages.data != 0">
                  <div class="reply_wrap" v-for="(message, index) in reply_messages" :key="message.id" :id="'reply'+(index+1)">
                    <p class="res_head">
                      <span class="res_num en">{{index + 1}}</span><span class="en">.</span>
                      <span class="res_name" v-if="message.title == '削除された返信コメント'"> ― </span>
                      <span class="res_name" v-else>{{ message.create_user.last_name }} {{ message.create_user.first_name }}</span>
                      <span class="res_day_time">
                        <img src="@/assets/front_component/images/assets/board01.png" alt="時間">
                        <time class="en" :datetime="formatDate_time(message.created_at)">{{ formattime(message.created_at) }}</time>
                      </span>
                    </p>
                    <p v-if="message.reply_com_id" class="p_t10"><a :href="'#reply'+message.reply_com_id">>> {{message.reply_com_id}}</a></p>
                    <div class="post_area">
                      <p v-html="htmlText(message.content)"></p>
                    </div>
                    <div class="control_area" v-if="message.title == '削除された返信コメント'">
                    </div>
                    <div class="control_area" v-else>
                      
                      <div class="good_button">
                        <div class="icon_wrap">
                          <img src="@/assets/front_component/images/assets/good03.png" alt="いいね" class="mouse_out">
                          <img src="@/assets/front_component/images/assets/good02.png" alt="いいね" class="mouse_over">
                        </div>
                        <span class="txt">いいね</span>
                        <span class="en good_num">{{ message.good_num }}</span>
                      </div>
                      
                      <div class="delete_button" @click="comDelete(message.id)">
                        <span class="txt">削除</span>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap -->
          </div>
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>

  <div
    class="modal"
    id="confirmModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">確認</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>アーカイブに移動しますか？</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscard()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirm()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscard()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
        <i class="fas fa-times"></i>
      </div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->

  <div
    class="modal"
    id="confirmModalCom"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal_inner confirm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form_outwrap">
              <div class="modal_header">
                <p class="ttl_header">確認</p>
              </div>
              <div class="modal_wrap">
                <div class="for_wrap h_80">
                  <div class="modal_contents">
                    <p>
                      <span>コメントを削除しますか？</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="modal_footer">
                <div class="flex_wrap f_c">
                  <div class="btn_norm reverse" id="cancel_bk">
                    <button
                      name=""
                      value="キャンセル"
                      class="close"
                      dusk="delete_data"
                      @click="closeDiscardCom()"
                    >
                      キャンセル
                    </button>
                  </div>
                  <div class="btn_norm delete">
                    <button
                      @click="deleteConfirmCom()"
                      class="btn btn"
                      value="アーカイブ"
                      title="削除"
                      dusk="delete_data"
                    >
                      実行
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        id="close_btn"
        class="pop-remove_btn ttoltip tip-top close"
        @click="closeDiscardCom()"
      >
        <i class="fas fa-times"></i>
      </p>
      <!-- <div class="close_btn tooltip tip-left" title="閉じる">
        <i class="fas fa-times"></i>
      </div> -->
    </div>
    <!--modal_inner-->
  </div>
  <!--modal-->
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from 'jquery';
import message from "@/apis/Message";
import user from "@/apis/User";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import dayjs from 'dayjs';
import "@/assets/front_component/js/jquery.magnific-popup.min.js";

export default {
  name: "message",
  data() {
    return {
		userlists:"",
      showmessage: {},
      MessageColumn() {
        return MessageColumn
      },
      messagecolumn: {},
      MessageType() {
        return MessageType
      },
      messagetype: {},
      files: {},
      images: {},
      reply_messages: [],
      approval_ng:{},
      approval_post_data:{},
      com_del_id:"",
      delCom: {
        title:'削除された返信コメント',
        content:'このコメントは削除されました',
        content_type:'HTML',
        status:'公開',
        type:'board_res',
      },
    };
  },
  components: {  },
  beforeCreate(){
    // $(function(){   
    //   // body に open クラスをつけたりはずしたりする( open クラスは空)
    //   $('.g_header_list').removeClass('open');
    //   $('#button').removeClass('active');
    //   $(document.body) .removeClass('open');
    //   var result = $('body').attr('style');
    //   var int_data = result.replace(/[^0-9]/g, '');
    //   $("html,body").css({
    //     "overflow": "auto",
    //     "height": "auto",
    //   });
    //   //bodyfixedを解除する
    //   $('body').css({
    //     'position': '',
    //     'width': '',
    //     'top': ''
    //   });
    //   $(window).scrollTop(int_data); 
    //   $('.g_header_list  > li.has_child').removeClass('open');
    //   $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    // });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.message_type = 'board';
    this.message_id = this.$route.params.id;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.ShowMessage(this.message_id);
    this.getFile(this.message_id);
    this.getImage(this.message_id);
    this.goodList(this.message_id);
    this.replyList(this.message_id);
  },
  mounted() {
    document.body.className = 'page_control';
		this.$nextTick(function() {
			$(function(){
				$('.menu_icon').click(function(){
					if($('.menu_list_wrap').hasClass('show')){
						$('.menu_list_wrap').removeClass('show');
					}else{
						$('.menu_list_wrap').addClass('show');
					}
				});
			});
			$(document).click(function(event) {
				if(!$(event.target).closest('.menu_wrap').length) {
					$('.menu_list_wrap').removeClass('show');
				}
			});
			$(function(){
				var content_height = $("#app").height(); // コンテンツの高さを取得
				var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
				$(window).on("scroll", function () {
					var scroll = $(window).scrollTop();
					var windowHeight = $(window).height();
					//ページトップ表示
					if(scroll > pagetop_show && scroll>60){
						$('.pagetop_wrap').addClass('show');
					} else {
						$('.pagetop_wrap').removeClass('show');
					}
				});
			});
			$(function(){
				$('#js-loader').delay(300).fadeOut(600);
				$(window).scrollTop(0);
			});
      $(document).ready(function () {
        var hsize = $(window).height();
        $(".maincontent").css("min-height", hsize - 60 + "px");
      });
      $(".js-modal-open").on("click", function () {
        $("#confirmModal").fadeIn();
        $("#confirmModal").addClass("open");
      });
      $(document).on('click', '.delete_button', function(){
        $("#confirmModalCom").fadeIn();
        $("#confirmModalCom").addClass("open");
      });
      //Jquery
      // youtubeがタグの構成上表示出来ないので以下でタグを変換している
      $(function () {
        $("figure.media").each(function () {
          var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
          $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
          $(this)
            .children()
            .replaceWith(
              "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
            );
            console.log($y_url);
          $(this)
            .children(".post_youtube")
            .attr("src", "https://www.youtube.com/embed/" + $y_url);
        });
      });
		});
  },
  methods: {
    comDelete(messageid){
      this.com_del_id = messageid;
    },
    closeDiscardCom(){
      $("#confirmModalCom").removeClass("open");
      this.com_del_id = "";
    },
    deleteConfirmCom(){
      this.deletemessageCom(this.com_del_id);
    },
    deletemessageCom(messageid) {
      message.update(this.delCom,messageid)
      .then((response) => {
        if (response != null) {
          console.log(response);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loader = false;
      });
    },
    deletemessage(messageid) {
      message.delete(messageid)
      .then((response) => {
        if (response != null) {
          console.log(response);
          this.$router.push({ name: 'Admin board archive', params: { type: 'board' }});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loader = false;
      });
    },
    deleteConfirm(){
      this.deletemessage(this.message_id);
    },
    closeDiscard(){
      $("#confirmModal").removeClass("open");
    },
    htmlText(msg){
      return msg.replace(/\r?\n/g, '<br>')
    },
    isRequired(value) {
      if (value) {
        return true;
      }
      return '必須です';
    },
    goodList(id) {
      this.loader = true;
      message.listChild(id,'board_good')
      .then(
        (response) => {
          if (response != null) {
            this.showmessage.good_num = response.data.data.length;
          }
        },
        (error) => {
          console.log(error);
          console.log(error.response.data.errors);
          this.$router.push({ name: 'Front Error' });
        }
      )
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
        this.loader = false;
      });
    },
    reply_goodList(id,i) {
      this.loader = true;
      message.listChild(id,'board_good')
      .then(
        (response) => {
          if (response != null) {
            this.reply_messages[i].good_num = response.data.data.length;
            response.data.data.forEach((ob) => {
              console.log(ob);
              if(ob.act_users[0].user_id == this.$User.id){
                console.log(ob.act_users[0].user_id);
                this.reply_messages[i].good_self = true;
              }
            });
          }
        },
        (error) => {
          console.log(error);
          console.log(error.response.data.errors);
          this.$router.push({ name: 'Front Error' });
        }
      )
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
        this.loader = false;
      });
    },
    replyList(id) {
      this.loader = true;
      message.listChild(id,'board_res')
      .then(
        (response) => {
          if (response != null) {
            this.reply_messages = response.data.data;
            this.showmessage.reply_num = response.data.data.length;

            this.reply_messages.forEach((ob,i) => {

              user.show(ob.act_users[0].user_id)//投稿者の取得
              .then((response) => {
                if (response != null) {
                  this.reply_messages[i].create_user = response.data.data;
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
              });

              this.reply_messages[i].good_self = false;//いいねの取得
              this.reply_goodList(ob.id,i);

              message.getMeta(ob.id)//metaの取得
              .then((response) => {
                if (response != null) {
                  if(response.data.data[0]){
                    this.reply_messages[i].reply_com_id = response.data.data[0].value;
                    console.log(response.data.data[0].value);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
              });

            });

            console.log(this.reply_messages);
          }
        },
        (error) => {
          console.log(error);
          console.log(error.response.data.errors);
          this.$router.push({ name: 'Front Error' });
        }
      )
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
        this.loader = false;
      });
    },
    formatDate: dateStr => dayjs(dateStr).format('YYYY.MM.DD'),
    formatDate_time: dateStr => dayjs(dateStr).format('YYYY-MM-DD'),
    formattime: dateStr => dayjs(dateStr).format('YYYY-MM-DD hh:mm:ss'),
    ShowMessage(id) {
      message.show(id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
			console.log(this.showmessage);
			this.getMessageMeta(id);
			this.getMessageEyecatch(id);
			this.getMessageAttachedFile(id);
            if(this.showmessage.from_id != null){
              this.getFromUser(this.showmessage.from_id);
            }
            if(this.showmessage.target_ids != null){
              this.getTargetUser(this.showmessage.target_ids);
            }
			// console.log(this.showmessage.attached_file);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
		
    },
    getFromUser(user_id) {
      user.show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getTargetUser(user_ids) {
      this.showmessage.target_user = [];
      user_ids.forEach(user_id => {
        user.show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.target_user.push(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
      });
      
    },
	getMessageMeta(message_id) {
		if(this.messagecolumn['sdgs_type']){
			message.showmetakey(message_id,'key','sdgs_type')
			.then((response) => {
				if (response != null) {
					this.showmessage.sdgs_type = response.data.data[0].value;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
		if(this.messagecolumn['group']){
			message.showmetakey(message_id,'key','group')
			.then((response) => {
				if (response != null) {
					this.showmessage.group = response.data.data[0].value;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
		if(this.messagecolumn['public']){
			message.showmetakey(message_id,'key','public')
			.then((response) => {
				if (response != null) {
					this.showmessage.public = response.data.data[0].value;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
		if(this.messagecolumn['thanks_type']){
			message.showmetakey(message_id,'key','thanks_type')
			.then((response) => {
				if (response != null) {
					this.showmessage.thanks_type = response.data.data;
					console.log(this.showmessage.thanks_type);
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
      
    },

	getMessageEyecatch(message_id) {
		
		if(this.messagecolumn['photolog']){
			message.showfile(message_id,'key','photolog')
			.then((response) => {
				if (response != null) {
					this.showmessage.photolog = response.data.data[0];
					let fileApiPass = '/api/v1/file/' + this.showmessage.photolog.storage_file_name;
						console.log(fileApiPass);
						Api().get(fileApiPass,
							{responseType: 'arraybuffer'}
						)
						.then(response => {
							let blob= new Blob([response.data], { type: response.headers['content-type'] })
							let img = document.getElementById('img_tag_id')
							let url = window.URL || window.webkitURL
							img.src = url.createObjectURL(blob)

						});
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
      
    },
	getMessageAttachedFile(message_id) {
		if(this.messagecolumn['attached_file']){
			message.showfile(message_id,'key','attached_file')
			.then((response) => {
				if (response.data.data.length != 0) {
					this.showmessage.attached_file = response.data.data;
				}
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
		}
      
    },
    getFile(message_id) {
      message.showfile(message_id,'key','documents')
        .then(
          (response) => {
            if (response != "") {
              this.files = response.data.data;
              console.log(this.files);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: 'Front Error' });
          }
        )
        .catch(() => {
          this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
    getImage(message_id) {
      message.showfile(message_id,'key','post_images')
        .then(
          (response) => {
            if (response != "") {
              this.images = response.data.data;
              this.images.forEach((ob,i) => {

                let path = ob.storage_file_name;
                let fileApiPass = '/api/v1/file/' + path;
                console.log(fileApiPass);
                Api().get(fileApiPass,
                  {responseType: 'arraybuffer'}
                )
                .then(
                  (response) => {
                    let blob= new Blob([response.data], { type: response.headers['content-type'] })
                    let url = window.URL || window.webkitURL
                    let src = url.createObjectURL(blob)
                    this.images[i].imgpath = src
                  },
                  (error) => {
                    console.log(error);
                    console.log(error.response.data.errors);
                  }
                )

              });

              $(function(){
                $('.pop-modal').magnificPopup({
                  type: 'inline',
                  preloader: false, 
                  closeOnContentClick: false,
                  closeBtnInside: true,
                  removalDelay: 300,
                  gallery: { //ギャラリー表示にする
                    enabled: true,
                  } 
                });
              });
              console.log(this.images);
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: 'Front Error' });
          }
        )
        .catch(() => {
          this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },
	dlfile(path){
      let fileApiPass = '/api/v1/file/' + path;
      console.log(fileApiPass);
      Api().get(fileApiPass,
        {responseType: 'arraybuffer'}
      )
      .then(response => {
        let blob= new Blob([response.data], { type: response.headers['content-type'] })
        /**
         * 新規タブ
         */
        window.open(window.URL.createObjectURL(blob))
      });
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
.post_img .post_img_wrap{
  width: 49%;
  float: left;
  margin-bottom: 20px;
}
.post_img .post_img_wrap:not(:nth-child(2)){
  margin-right: 2%;
}
.post_img .post_img_wrap a{
  display: block;
}
.post_img .post_img_wrap a:hover{
  opacity: 1;
}
.post_img .post_img_wrap a:hover .post_img_item .hover_text{
  opacity: 1;
}
.post_img .post_img_wrap a .post_img_item{
  padding-top: 66.512%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.post_img .post_img_wrap a .post_img_item .hover_text{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: 0.2s;
}
.post_img .post_img_wrap a .post_img_item .hover_text .txt_wrap{
  padding: 0 .5em;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
}
.post_img .post_img_wrap a .post_img_item .hover_text .txt_wrap i{
  color: #fff;
  font-size: 2.6rem;
}
div.inline-wrap{
  height: auto;
  background-color: transparent;
}
.pop-modal{
  border-bottom: none;
}
.reply_wrap {
  padding: 20px 30px 20px;
  background-color: #eaf6f6;
  border-radius: 10px;
}
.reply_wrap:not(:first-of-type){
  margin-top: 10px;
}
.reply_wrap .res_head>span{
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.reply_wrap .res_head .res_num{
  font-size: 2.2rem;
  font-weight: bold;
  vertical-align: sub;
}
.reply_wrap .res_head .res_num+.en{
  font-size: 2.2rem;
  font-weight: bold;
  vertical-align: sub;
}
.reply_wrap .res_head .res_name{
  font-size: 2rem;
  font-weight: bold;
  margin-right: 15px;
  max-width: calc(100% - 196px);
}
.reply_wrap .res_head .res_day_time img,.reply_wrap .res_head .res_day_time time{
  vertical-align: middle;
}
.reply_wrap .res_head .res_day_time time{
  font-size: 1.4rem;
}
.reply_wrap .post_area{
  padding-top: 4px;
  margin-bottom: 10px;
}
.reply_wrap .post_area p{
  margin-top: 0;
  margin-bottom: 0!important;
  line-height: 1.7;
}
.reply_wrap .control_area{
  text-align: right;
}
.reply_wrap .control_area .good_button,.reply_wrap .control_area .delete_button{
  display: inline-block;
  vertical-align: middle;
}
.reply_wrap .control_area .good_button>div,.reply_wrap .control_area .good_button>span{
  display: inline-block;
  vertical-align: middle;
}
.reply_wrap .control_area .good_button .icon_wrap{
  width: 32px;
  height: 32px;
  background-color: #ceeaed;
  border-radius: 50%;
  position: relative;
  box-sizing:border-box;
  margin-right: 5px;
}
.reply_wrap .control_area .good_button .icon_wrap img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.reply_wrap .control_area .good_button .icon_wrap img.mouse_over{
  opacity: 0;
}
.reply_wrap .control_area .good_button .txt{
  padding-right: 0.2em;
  font-size: 1.4rem;
}
.reply_wrap .control_area .good_button .good_num{
  font-size: 1.4rem;
  padding-top: 3px;
  margin-left: 1px;
}
.reply_wrap .control_area .delete_button{
  margin-left: 1em;
  transition: .2s;
}
.reply_wrap .control_area .delete_button:hover{
  cursor: pointer;
  opacity: .8;
}
.reply_wrap .control_area .delete_button .txt{
  font-size: 1.4rem;
  color: #ca5a7c;
  vertical-align: text-top;
}
</style>
